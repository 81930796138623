import { environment } from "src/environments/environment";
export class orderUrls {
  onDemand = environment.ondemandUrl;
  comm = environment.commonUrl


  // Store Type
  storeType: string = `${this.onDemand}/admin/store/storeType`;
  bulkUploadStoreType: string = `${this.onDemand}/admin/store/bulkUploadStoreType`;
  exportStoreType: string = `${this.onDemand}/admin/store/getAllStoreTypeExport`;
  deleteSelectedStoreTypes: string = `${this.onDemand}/admin/store/deleteSelectedStoreTypes`;
  deleteAllStoreTypes: string = `${this.onDemand}/admin/store/deleteAllStoreTypes`;
  getMerchantOutlet: string = `${this.onDemand}/admin/store/outletforSingleVendor`;
  changeArrangingOrder: string = `${this.onDemand}/admin/store/changeArrangingOrder`;
  changeCategoriesOrder: string = `${this.onDemand}/admin/store/changeArrangingOrderCategory`;


  // Category
  category: string = `${this.onDemand}/admin/store/category`;
  allCategoryStore: string = `${this.onDemand}/admin/store/getStoreAllCategory`;
  updateCategoryListByStoreId: string = `${this.onDemand}/admin/store/updateCategoryListByStoreId`;
  bulkUploadCategory: string = `${this.onDemand}/admin/store/bulkUploadCategory`;
  exportCategory: string = `${this.onDemand}/admin/store/getAllCategoryExport`;
  deleteSelectedCategory: string = `${this.onDemand}/admin/store/deleteSelectedCategory`;
  deleteAllCategory: string = `${this.onDemand}/admin/store/deleteAllCategory`;
  getCategoriesByStoreType: string = `${this.onDemand}/admin/store/getCategoriesByStoreType`;
  getBrandsByStoreType: string = `${this.onDemand}/admin/store/getBrandsByStoreType`;
  getSubCategoriesByStoreType: string = `${this.onDemand}/admin/store/getSubCategoriesByStoreType`;


  // subCategory
  subCategory: string = `${this.onDemand}/admin/store/subCategory`;
  deleteAllSubCategory: string = `${this.onDemand}/admin/store/deleteAllSubCategory`;
  bulkUploadSubCategory: string = `${this.onDemand}/admin/store/bulkUploadSubCategory`;
  getAllSubCategoryExport: string = `${this.onDemand}/admin/store/getAllSubCategoryExport`;
  deleteSelectedStoreSubCategory: string = `${this.comm}/admin/store/deleteSelectedSubCategory`;
  getSubCategories: string = `${this.onDemand}/admin/store/getsubcategories`;


  // Brands
  brands: string = `${this.onDemand}/admin/store/brand`;
  deleteAllBrand: string = `${this.onDemand}/admin/store/deleteAllBrand`;
  deleteSelectedBrand: string = `${this.onDemand}/admin/store/deleteSelectedBrand`;
  getAllBrandExport: string = `${this.onDemand}/admin/store/GetAllBrandExport`;
  bulkUploadBrand: string = `${this.onDemand}/admin/store/bulkUploadBrand`;


  // Products
  products: string = `${this.onDemand}/admin/store/product`;
  itembyid: string = `${this.onDemand}/admin/store/itembyid`;
  variant: string = `${this.onDemand}/admin/store/variant`;
  deleteSelectedProduct: string = `${this.onDemand}/admin/store/deleteSelectedProduct`;
  deleteAllProduct: string = `${this.onDemand}/admin/store/deleteAllProduct`;
  getAllProductExport: string = `${this.onDemand}/admin/store/getAllProductExport`;
  bulkUploadProduct: string = `${this.onDemand}/admin/store/bulkUploadProduct`;
  bulkTopUp: string = `${this.onDemand}/wallet/bulkTopUp`;
  getprototypeProducts: string = `${this.onDemand}/admin/store/getprototypeProducts`;
  getDeleteRating: string = `${this.onDemand}/admin/store/productRatingReview`;



  // Driver
  driverOrder: string = `${this.onDemand}/admin/driver/getDriverAllOrders`;


  //Pricing
  addeditPricing: string = `${this.comm}/admin/pricing`;
  getAllPricing: string = `${this.comm}/admin/getAllPricing`;
  // reset pass
  resetStorePassword: string = `${this.comm}/store/updateMerchantPassword`;

  // get signed url
  getSignedUrl: string = `${this.comm}/upload/getSignedUrl`;
  getSignedUrlCommon: string = `${this.comm}/upload/getSignedUrl`;
  getSignedUrlOndemand: string = `${this.onDemand}/admin/getSignedUrl`;
 




// aggregator
   aggregator: string = `${this.onDemand}/admin/store/getAggregators`;
  // Store
  store: string = `${this.onDemand}/admin/store`;
  editAggregatorContract:string = `${this.onDemand}/admin/store/editAggregatorContract`;
  storeByOrderCount: string = `${this.onDemand}/admin/store/store-by-orders-count`;
  storeById: string = `${this.onDemand}/admin/store/getStoreById`;
  getPendingStores: string = `${this.onDemand}/store/newStore`;
  getMerchantCategories: string = `${this.onDemand}/admin/store/getcategories`;
  getBrandsByStore: string = `${this.onDemand}/admin/store/getBrandsByStore`;
  getMerchantSubCategories: string = `${this.onDemand}/admin/store/getMerchantAllSubCategories`;
  getMerchantsFromType: string = `${this.onDemand}/admin/store/getStoresByType`;
  storeAllOrders: string = `${this.onDemand}/admin/store/orders/all`;
  deleteSelectedStores: string = `${this.onDemand}/admin/store/deleteSelectedStores`;
  deleteAllStores: string = `${this.onDemand}/admin/store/deleteAllStores`;
  addTimeSlot: string = `${this.onDemand}/admin/addTimeSlot`;
  getTimeSlots: string = `${this.onDemand}/admin/getTimeSlot`;
  getAllMarchantsCSV: string = `${this.onDemand}/admin/getAllMarchantsCSV`;
  getStoreDocuments: string = `${this.comm}/store/document`;
  getAllStoreExport: string = `${this.onDemand}/admin/getAllStoreExport`;
  getDocument: string = `${this.onDemand}/store/getDocumentList`;
  getMerchantWiseCommission: string = `${this.onDemand}/admin/payoutList`
  bulkUploadStore: string = `${this.onDemand}/admin/bulkUploadStore`;
  updateStoreDocumentStatus: string = `${this.onDemand}/admin/store/verifyDocument`;
  createStoreInvoice: string = `${this.onDemand}/admin/store/createStoreInvoice`;
  updateInvoice: string = `${this.onDemand}/admin/store/updateInvoice`;
  createStoreInvoiceWithAggregators: string = `${this.onDemand}/admin/store/createStoreInvoiceWithAggregators`;
  createBulkAggregatorStoreInvoice: string = `${this.onDemand}/admin/store/createBulkAggregatorStoreInvoice`
   createMultipleAggregatorStoreInvoice: string = `${this.onDemand}/admin/store/createMultipleAggregatorStoreInvoice`
  updateStoreInvoice: string = `${this.onDemand}/admin/store/updateStoreInvoice`;
  createBulkStoreInvoice: string = `${this.onDemand}/admin/store/createBulkStoreInvoice`;
  sendInvoiceToStore: string = `${this.onDemand}/admin/store/sendInvoiceToStore`;
  getStoreInventory: string = `${this.onDemand}/admin/store/getStoreInventory`;
  updateCloneProducts: string = `${this.onDemand}/admin/store/updatecloneProducts`;
  changeSubOrderStatus: string = `${this.onDemand}/admin/store/changeSubOrderStatus`;
  getCatWithProdThroughStoreId: string = `${this.onDemand}/admin/store/updatecloneProducts`;
  serviceSlots: string = `${this.onDemand}/admin/store/serviceSlots`;
  blockCustomSlot: string = `${this.onDemand}/admin/store/customSlot`;
  getStoreTokenById: string = `${this.onDemand}/admin/store/getStoreTokenById`;
  holidaySlot: string = `${this.onDemand}/admin/store/holidayTimingService`


  // Order Manual Assignment
  getAllNearDrivers: string = `${this.onDemand}/admin/driver/getAllNearDrivers`;


  addVariantToInventory: string = `${this.onDemand}/admin/store/cloneProduct`;
  getStoreInventoryAll: string = `${this.onDemand}/admin/store/getStoreInventoryAll`;
  cloneAll: string = `${this.onDemand}/admin/store/cloneAll`;


  // Outlet
  outlet: string = `${this.onDemand}/admin/store/outlet`;
  getWarehouseOutlet: string = `${this.onDemand}/admin/store/getAllOutlets`;


  // Order List
  getAllOrdersCSV: string = `${this.onDemand}/admin/getAllOrdersCSV`;
  getAllOrdersList: string = `${this.onDemand}/admin/store/merchantOrdersExport`;
  getAllOrders: string = `${this.onDemand}/admin/store/orders/all`;
  bulkUploadOrder: string = `${this.onDemand}/admin/store/bulkUploadOrder`;
  getAllOrderExport: string = `${this.onDemand}/admin/store/getAllOrderExport`;
  assignDriverManually: string = `${this.onDemand}/admin/store/manuallyAssignOrder`;
  createOrderByAdmin: string = `${this.onDemand}/admin/createOrderByAdmin`;
  getOrderInvoice: string = `${this.onDemand}/admin/store/pdfOrder`;
  getOrderInvoiceByMerchant: string = `${this.onDemand}/admin/store/pdfOrderByMerchant`;

  verticalWiseOrdersExport:string= `${this.onDemand}/admin/store/verticalWiseOrdersExport`;
  distanceWiseOrdersExport:string=`${this.onDemand}/admin/store/distanceWiseOrdersExport`;
  merchantOrdersPaymentTypeExport:string=`${this.onDemand}/admin/store/merchantOrdersPaymentTypeExport`;


  // User
  user: string = `${this.comm}/admin/user`;
  getUserAllOrders: string = `${this.onDemand}/admin/getAllOrdersCSV`;


  //Sales Person
  getStoresSP: string = `${this.onDemand}/admin/getStoresSP`;


  // Tickets
  Tickets: string = `${this.onDemand}/admin/tickets`;
  assignTickets: string = `${this.onDemand}/admin/store/assignTickets`;
  announceWinner: string = `${this.onDemand}/admin/store/winner`;


  // Campaigns
  campaign: string = `${this.onDemand}/admin/campaign`;
  getWinnerList: string = `${this.onDemand}/admin/store/getwinners`;


  // Add On's  Toppings
  AddOns: string = `${this.onDemand}/admin/addOns`;
  getAddOn: string = `${this.onDemand}/admin/getAddOn`;
  deleteAddOns: string = `${this.onDemand}/admin/deleteAddOns`;
  getToppingItems: string = `${this.onDemand}/admin/getToppingItems`;
  getPendingOrderCount: string = `${this.onDemand}/admin/pendingOrderCount`;

  exportWalletData:string=`${this.onDemand}/wallet/exportWalletData`;
  walletList:string=`${this.onDemand}/wallet/walletList`;
  riderPayout:string=`${this.onDemand}/wallet/riderPayout`;
  merchantPayout:string=`${this.onDemand}/wallet/merchantPayout`;
  

  allRiderPayout:string=`${this.onDemand}/wallet/allRiderPayout`;

  Driver:string= `${this.onDemand}/admin/exportWalletBalance/Driver`

  Merchant:string= `${this.onDemand}/admin/exportWalletBalance/Merchant`;

  //logs 
  Logs:string= `${this.onDemand}/admin/logs`;

  //aggregator
  onBoardAggregator: string = `${this.onDemand}/admin/store/onBoardAggregator`;

  //geofence
  geofence: string = `${this.onDemand}/admin/geofence`;

  //vehicle
  vehicleList: string = `${this.onDemand}/admin/vehicleList`;
 
  //cod wallet report
  pendingCodReport: string = `${this.onDemand}/wallet/pendingCodReport`

  //cod wallet report
  deleteStore: string = `${this.onDemand}/store/deleteStore`

  //downloadWalletSample
  downloadWalletSample:string = `${this.onDemand}/wallet/downloadWalletSample`

  //exportNegativeBalanceReport
  exportNegativeBalanceReport:string= `${this.onDemand}/admin/exportNegativeBalanceReport`;

  //exportGetMerchantWalletReport
  exportGetMerchantWalletReport:string= `${this.onDemand}/admin/getMerchantWalletReport`;

  //getWalletOperationLogs
  getWalletOperationLogs:string = `${this.onDemand}/wallet/getWalletOperationLogs`;

  //merchantOrdersExportV2
  merchantOrdersExportV2: string = `${this.onDemand}/admin/store/merchantOrdersExportV2`

  //getActiveMerchants
  getActiveMerchants: string = `${this.onDemand}/admin/store/getActiveMerchants`

  //broadcastToMerchants
  broadcastToMerchants: string = `${this.onDemand}/admin/broadcastToMerchants`;

  //excelBulkRiderPayout
  excelBulkRiderPayout:string = `${this.onDemand}/wallet/excelBulkRiderPayout`

}
